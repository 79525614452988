import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface GoodiesProps {
  onClose: () => void;
}

export const goodiesData: ProjectData = {
  id: 'goodies',
  title: "The Goodies",
  tagline: 'A way to celebrate those who have a meaningful and positive impact on the community',
  year: '2016',
  role: 'Community Builder',
  status: 'ideas',
  type: 'event',
  thumbnailImage: '/img/projects/goodies/thumbnail.png',
  overview: {
    description: "An event to connect and celebrate good people. The Goodies brings together the go-getters and do-gooders who are working tirelessly and selflessly, and mostly in anonymity, to make our community an even better place to live.",
    motivation: "The idea for the Goodies emerged from the realization that there are so many wonderful people who are working to make our community a happy, healthy, beautiful, and interesting place to live; a good place. Yet, many of these people do not even know each other. The Goodies marks the beginning of a virtuous cycle - a positive feedback loop that brings together a critical mass of caring citizens to ignite new ideas, form new friendships, that hopefully create an even better community!",
    solution: "The Annual Good Citizens Awards ('The Goodies') was intended to be a fun, interactive, entertaining awards celebration event. It was originally planned ot occur in Orlando, FL in 2017."
  },
  stats: [
    {
      value: '1',
      label: 'Annual Event',
      icon: 'bi-calendar'
    },
    {
      value: '100',
      label: 'Attendees',
      icon: 'bi-people'
    },
    {
      value: 'Lots of',
      label: 'Celebration and recognition',
      icon: 'bi-people'
    }
  ],
  gallery: [
    {
      image: '/img/projects/goodies/gallery1.jpg',
      caption: 'Goodies'
    },
    {
      image: '/img/projects/goodies/gallery2.jpg',
      caption: 'Goodies'
    },
    {
      image: '/img/projects/goodies/gallery3.jpg',
      caption: 'Goodies'
    }
  ],
  resources: [
  ]
};

const Goodies: React.FC<GoodiesProps> = ({ onClose }) => {
  return <ProjectTemplate data={goodiesData} />;
};

export default Goodies;