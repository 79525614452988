import React, { useEffect } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import ProjectTemplate from './projects/ProjectTemplate';
import { ProjectData } from './projects/ProjectTemplate';
import { htiData } from './projects/HTI';
import { ahahData } from './projects/AHAH';
import { bandBaconData } from './projects/BandBacon';
import { gatherlyData } from './projects/Gatherly';
import { muzedData } from './projects/Muzed';
import { nnoData } from './projects/NNO';
import { rafteoData } from './projects/Rafteo';
import { slspData } from './projects/SLSP';
import { speedfriendingData } from './projects/Speedfriending';
import { beasterData } from './projects/Beaster';
import { pogonautsData } from './projects/Pogonauts';
import { tgbData } from './projects/TGB';
import { seededData } from './projects/Seeded';
import { goodiesData } from './projects/Goodies';
import { trailfriendData } from './projects/Trailfriend';
// Import other project data

type ProjectMap = {
  [key: string]: ProjectData;
};

const ProjectPage: React.FC = () => {
  const { projectId } = useParams();

  // Simple scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array = only on mount

  // Map of all project data with proper typing
  const projectDataMap: ProjectMap = {
    slsp: slspData,
    nno: nnoData,
    hti: htiData,
    speedfriending: speedfriendingData,
    gatherly: gatherlyData,
    muzed: muzedData,
    bandbacon: bandBaconData,
    trailfriend: trailfriendData,
    rafteo: rafteoData,
    goodies: goodiesData,
    ahah: ahahData,
    seeded: seededData,
    tgb: tgbData,
    pogonauts: pogonautsData,
    beaster: beasterData,
    // Add other projects here
  };

  const projectData = projectId ? projectDataMap[projectId] : null;

  // Create array of project navigation items
  const allProjects = Object.values(projectDataMap).map(project => ({
    id: project.id,
    title: project.title,
    tagline: project.tagline,
    thumbnailImage: project.thumbnailImage,
    status: project.status
  }));

  // If no matching project is found, redirect to home
  if (!projectData) {
    return <Navigate to="/" replace />;
  }

  return <ProjectTemplate data={projectData} allProjects={allProjects} />;
};

export default ProjectPage; 