import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface NNOProps {
  onClose: () => void;
}

export const nnoData: ProjectData = {
  id: 'nno',
  title: 'Nerd Nite Orlando',
  tagline: 'It\'s like the Discovery Channel with beer',
  year: '2013',
  role: 'Nerdboss',
  status: 'ongoing',
  type: 'community',
  thumbnailImage: '/img/projects/nno/thumbnail.png',
  overview: {
    description: "Nerd Nite is an evening of entertaining yet educational presentations. Speakers present for 20-25 minutes each on a fascinating subject of their choice, often accompanied by PowerPoint presentations and/or demonstrations. Meanwhile, the audience drinks along in an informal bar atmosphere.",
    motivation: "To create a space where experts (and people who think they're experts...nor not) and enthusiasts can share their passions with a receptive (and often inebriated) audience. The informal bar setting helps break down barriers between speakers and audience, fostering actual connections and genuine conversations about fascinating, and sometimes very obscure, topics.",
    solution: "Monthly events featuring three speakers, a bar, and a room full of curious minds ready to learn about anything and everything."
  },
  activities: [
    {
      title: 'Monthly Events',
      description: 'Three speakers, 20-25 minutes each, sharing their quirky, nerdy, and sometimes obscure topics with a curious audience.',
      icon: 'bi-calendar-event'
    },
    {
      title: 'Diverse Topics',
      description: 'From quantum physics to the history of cheese, to getting high in outer space nothing is off limits.',
      icon: 'bi-lightbulb'
    },
    {
      title: 'Community Building',
      description: 'Building a community of nerds ready to learn about anything and everything.',
      icon: 'bi-people'
    }
  ],
  gallery: [
    {
      image: '/img/projects/nno/gallery1.jpg',
      caption: 'NNO Event'
    },
    {
      image: '/img/projects/nno/gallery2.jpg',
      caption: 'Presentation Night'
    },
    {
      image: '/img/projects/nno/gallery3.jpg',
      caption: 'Audience Engagement'
    },
    {
      image: '/img/projects/nno/gallery4.jpg',
      caption: 'Community Building'
    }
  ],
  stats: [
    {
      value: '100+',
      label: 'Total Events',
      icon: 'bi-calendar-check'
    },
    {
      value: '300+',
      label: 'Presentations',
      icon: 'bi-mic'
    },
    {
      value: '10+',
      label: 'Years Running',
      icon: 'bi-hourglass'
    }
  ],
  links: [
    {
      url: 'https://orlando.nerdnite.com',
      label: 'NNO Website',
      icon: 'bi-globe'
    },
    {
      url: 'https://www.facebook.com/NerdNiteOrlando',
      label: 'NNO Facebook',
      icon: 'bi-facebook'
    }
  ]
};

const NNO: React.FC<NNOProps> = ({ onClose }) => {
  return <ProjectTemplate data={nnoData} />;
};

export default NNO; 