import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface HTIProps {
  onClose: () => void;
}

// Project Data
export const htiData: ProjectData = {
  id: 'hti',
  title: 'How to Innovate',
  tagline: 'Making innovation accessible to everyone',
  year: '2025',
  role: 'Creator & Developer',
  status: 'ongoing',
  type: 'software',
  thumbnailImage: '/img/projects/hti/thumbnail.png',
  overview: {
    description: "Innovation isn't reserved for a select few. It's about learning how to tap into your creativity and applying the right tools and frameworks to bring ideas to life. With the right mindset and processes, anyone can become an innovator.",
    motivation: "Many people believe innovation is only for 'creative types' or requires some innate genius. This misconception prevents countless individuals from exploring their innovative potential and contributing valuable ideas to the world.",
    solution: "A comprehensive platform that demystifies innovation through personalized Innovation Style assessments, practical frameworks, and step-by-step guidance. By breaking down the innovation process into manageable steps and providing tailored insights, we're making innovation accessible to everyone."
  },
  keyFeatures: [
    {
      title: 'Innovation Style Assessment',
      description: 'Discover your unique approach to innovation through our comprehensive assessment tool.',
      icon: 'bi-lightbulb'
    },
    {
      title: 'Innovation Framework',
      description: 'Step-by-step guides and Innovation Framework that makes the innovation process clear and actionable.',
      icon: 'bi-grid-3x3-gap'
    },
    {
      title: 'Personalized Guidance',
      description: 'Tailored recommendations based on your Innovation Style to maximize your creative potential.',
      icon: 'bi-person-check'
    }
  ],
  gallery: [
    {
      image: '/img/projects/hti/gallery1.jpg',
      caption: 'Innovation Style Assessment Interface'
    },
    {
      image: '/img/projects/hti/gallery2.jpg',
      caption: 'Framework Implementation Guide'
    },
    {
      image: '/img/projects/hti/gallery3.jpg',
      caption: 'Resource Library Dashboard'
    },
    {
      image: '/img/projects/hti/gallery4.jpg',
      caption: 'Innovation Style Assessment Interface'
    },
    {
      image: '/img/projects/hti/gallery5.jpg',
      caption: 'Framework Implementation Guide'
    },
    {
      image: '/img/projects/hti/gallery6.jpg',
      caption: 'Resource Library Dashboard'
    }
  ],
  stats: [
    {
      value: 20,
      label: 'Assessment Questions',
      icon: 'bi-question-circle'
    },
    {
      value: 5,
      label: 'Innovation Styles',
      icon: 'bi-people'
    },
    {
      value: 6,
      label: 'Framework Steps',
      icon: 'bi-ladder'
    }
  ],
  technologies: [
    'React',
    'TypeScript',
    'Node.js',
    'MongoDB',
    'Express',
    'AWS'
  ],
  links: [
    {
      url: 'https://howtoinnovate.org',
      label: 'Visit Website',
      icon: 'bi-globe'
    },
    {
      url: 'https://github.com/yourusername/hti',
      label: 'View Source',
      icon: 'bi-github'
    }
  ]
};

// Component
const HTI: React.FC<HTIProps> = ({ onClose }) => {
  return <ProjectTemplate data={htiData} />;
};

export default HTI; 