import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/portfolio.css';
import { slspData } from './projects/SLSP';
import { ahahData } from './projects/AHAH';
import { nnoData } from './projects/NNO';
import { muzedData } from './projects/Muzed';
import { gatherlyData } from './projects/Gatherly';
import { bandBaconData } from './projects/BandBacon';
import { rafteoData } from './projects/Rafteo';
import { htiData } from './projects/HTI';
import { speedfriendingData } from './projects/Speedfriending';
import { beasterData } from './projects/Beaster';
import { pogonautsData } from './projects/Pogonauts';
import { tgbData } from './projects/TGB';
import { seededData } from './projects/Seeded';
import { goodiesData } from './projects/Goodies';
import { trailfriendData } from './projects/Trailfriend';
import FilterMenu from './FilterMenu';

type ProjectCategory = 'all' | 'ongoing' | 'works' | 'past' | 'ideas';

const Portfolio: React.FC = () => {
  const [activeFilter, setActiveFilter] = useState<ProjectCategory>('all');
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleProjectClick = (projectId: string) => {
    window.location.href = `/${projectId}`;
  };

  // Use project data files as source of truth
  const projects = [
    slspData,
    nnoData,
    htiData,
    speedfriendingData,
    gatherlyData,
    muzedData,
    bandBaconData,
    trailfriendData,
    rafteoData,
    goodiesData,
    ahahData,
    seededData,
    tgbData,
    pogonautsData,
    beasterData,    // Add other project data here
  ];

  return (
    <>
      <div className={`project-navigator ${isNavExpanded ? 'expanded' : ''}`}>
        <div className="navigator-header">
          <button 
            className="toggle-nav"
            onClick={() => setIsNavExpanded(!isNavExpanded)}
          >
            <i className={`bi bi-grid${isNavExpanded ? '-fill' : ''}`}></i>
            {isNavExpanded ? 'Close' : 'Project Portfolio'}
          </button>
        </div>
        
        {isNavExpanded && (
          <div className="navigator-grid">
            {projects.map(project => (
              <button 
                key={project.id} 
                onClick={() => handleProjectClick(project.id)}
                className="project-card"
              >
                <div className="project-card-image">
                  <img src={project.thumbnailImage} alt={project.title} />
                </div>
                <div className="project-card-content">
                  <h3>{project.title}</h3>
                  <p>{project.overview.description}</p>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="portfolio-page">
        <div className="container">
          <h2>Projects and Ideas</h2>
          <p>These are a few of the projects and things that have kept me busy over the years.</p>
          
          <div className="portfolio-grid">
            {projects.map(project => (
              <div 
                key={project.id}
                className={`portfolio-item ${activeFilter === 'all' || activeFilter === project.status ? 'show' : 'hide'}`}
              >
                <article 
                  className="portfolio-card"
                  onClick={() => handleProjectClick(project.id)}
                >
                  <div className="portfolio-image">
                    <img src={project.thumbnailImage} alt={project.title} />
                  </div>
                  <div className="portfolio-content">
                    <div className="portfolio-meta">
                      <span className={`meta-tag status ${project.status}`}>
                        {project.status === 'works' ? 'in progress' : 
                        project.status === 'ongoing' ? 'ongoing' :
                        project.status === 'past' ? 'past project' :
                        project.status === 'ideas' ? 'concept' : ''}
                      </span>
                      <span className="meta-tag type">{project.type}</span>
                      <span className="meta-tag year">{project.year}</span>
                    </div>
                    <h3 className="portfolio-title">{project.title}</h3>
                    <p className="portfolio-excerpt">{project.overview.description}</p>
                    <span className="view-project">
                      View Project 
                      <i className="bi bi-arrow-right"></i>
                    </span>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;