import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface BeasterProps {
  onClose: () => void;
}

export const beasterData: ProjectData = {
  id: 'beaster',
  title: 'Beaster',
  tagline: 'Beer and beast feast',
  year: '2014',
  role: 'Master of Ceremonies',
  status: 'past',
  type: 'event',
  thumbnailImage: '/img/projects/beaster/thumbnail.png',
  overview: {
    description: "An all-day Easter beer hunt and rare meat cookout event with a mythical storyline.",
    motivation: "To create a unique and memorable event with my closest friends, inspired by the one and only Lauris Vidal.",
    solution: "A whole pig ordered off Craigslist (seriously), other unusual meats, lots of rare beers, and live interpretation of the story of Beaster."
  },
  activities: [
    {
      title: 'Easter Beer Hunt',
      description: 'Hide beers in the woods behind the house and hunt for them like easter eggs. You keep what you find.',
      icon: 'bi-search-heart'
    },
    {
      title: 'Unusual Foods',
      description: 'A whole pig, rocky mountain oysters (deep, deep fried), and other unusual foods.',
      icon: 'bi-piggy-bank'
    },
    {
      title: 'The Legend of Beaster',
      description: 'A live interpretation of the story of Beaster.',
      icon: 'bi-radioactive'
    }
  ],
  gallery: [
    {
      image: '/img/projects/beaster/gallery1.jpg',
      caption: 'Beaster Event'
    },
    {
      image: '/img/projects/beaster/gallery2.jpg',
      caption: 'Beaster Event'
    },
    {
      image: '/img/projects/beaster/gallery3.jpg',
      caption: 'Beaster Event'
    }
  ],
  stats: [
    {
      value: '1',
      label: 'Whole Pig',
      icon: 'bi-calendar-check'
    },
    {
      value: '30+',
      label: 'Closest Friends',
      icon: 'bi-people'
    },
    {
      value: '100s',
      label: 'Easter Beers',
      icon: 'bi-beer'
    }
  ],
  links: [
  ]
};

const Beaster: React.FC<BeasterProps> = ({ onClose }) => {
  return <ProjectTemplate data={beasterData} />;
};

export default Beaster; 