import React, { useState } from 'react';
import ContactAdventure from './ContactAdventure';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const [showAdventure, setShowAdventure] = useState(false);

  return (
    <>
      <footer id="footer" className="footer">
        <div className="container">
          <div className="footer-content">
            <div className="copyright">
              <span>Made with ☕️ in Colorado • </span>
              <button 
                onClick={() => setShowAdventure(true)} 
                className="contact-link"
              >
                <i className="bi bi-compass"></i>
                <span>Get in touch!</span>
              </button>
            </div>
          </div>
        </div>
      </footer>
      {showAdventure && (
        <ContactAdventure onClose={() => setShowAdventure(false)} />
      )}
    </>
  );
};

export default Footer; 