import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface RafteoProps {
  onClose: () => void;
}

export const rafteoData: ProjectData = {
  id: 'rafteo',
  title: 'Rafteo River Systems',
  tagline: 'Innovative gear for river adventures',
  year: '2024',
  role: 'Tinkerer',
  status: 'ideas',
  type: 'product',
  thumbnailImage: '/img/projects/rafteo/thumbnail.png',
  overview: {
    description: "Rafteo River Systems designs and develops innovative technical gear for river sports that solve real problems faced by rafters, kayakers, and other river enthusiasts. Our focus is on creating equipment that improves functionality and makes river adventures more enjoyable.",
    motivation: "After years of rafting and rigging, I've encountered numerous challenges that could be solved with better-designed gear. Instead of continuing to use makeshift solutions or complaining about existing products, I decided to create my own solutions that address these common pain points in river sports, starting with a simple universal base and suite of accessories for camstraps.",
    solution: "Innovative river gear designs that solve real problems, starting with a universal base system for camstraps that improves functionality and ease of use."
  },
  keyFeatures: [
    {
      title: 'Universal Base',
      description: 'A versatile mounting system that works with standard camstraps.',
      icon: 'bi-grid'
    },
    {
      title: 'Modular Design',
      description: 'Interchangeable accessories for different use cases.',
      icon: 'bi-boxes'
    }
  ],
  gallery: [
    {
      image: '/img/projects/rafteo/gallery1.jpg',
      caption: 'Product Design'
    },
    {
      image: '/img/projects/rafteo/gallery2.jpg',
      caption: 'Field Testing'
    },
    {
      image: '/img/projects/rafteo/gallery3.jpg',
      caption: ''
    },
    {
      image: '/img/projects/rafteo/gallery4.jpg',
      caption: ''
    }
  ],
  links: [
    {
      url: 'https://rafteo.com',
      label: 'Coming Soon',
      icon: 'bi-globe'
    }
  ]
};

const Rafteo: React.FC<RafteoProps> = ({ onClose }) => {
  return <ProjectTemplate data={rafteoData} />;
};

export default Rafteo; 