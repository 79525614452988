import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface PogonautsProps {
  onClose: () => void;
}

export const pogonautsData: ProjectData = {
  id: 'pogonauts',
  title: 'Pogonauts',
  tagline: 'The great explorers of the facial frontier and makers of unusual mustache waxes.',
  year: '2014',
  role: 'Founder',
  status: 'past',
  type: 'community',
  thumbnailImage: '/img/projects/pogonauts/thumbnail.png',
  overview: {
    description: "Beard and mustache enthusiasts who attended the National Beard and Mustache Championships all over the country and raised funds for Movember. We also made unusual mustache waxes (think surf wax, fruity pebbles) under a brand called 'Stiff Upper Lip'.",
    motivation: "To have fun with friends. Simple as that.",
    solution: "Pogonology is the study of facial hair, including beards. Naut means to explore. Therefore Pogonauts are the great explorers of the facial frontier!"
  },
  activities: [
    {
      title: 'Beard and Mustache Championships',
      description: 'Attended the National Beard and Mustache Championships all over the country.',
      icon: 'bi-trophy'
    },
    {
      title: 'Stiff Upper Lip',
      description: 'Made unusual mustache waxes (think surf wax, fruity pebbles) under a brand called "St.',
      icon: 'bi-emoji-smile'
    },
    {
      title: 'Movember',
      description: 'Raised funds to support men\'s health.',
      icon: 'bi-people'
    }
  ],
  gallery: [
    {
      image: '/img/projects/pogonauts/gallery1.jpg',
      caption: 'Pogonauts'
    },
    {
      image: '/img/projects/pogonauts/gallery2.jpg',
      caption: 'Pogonauts'
    },
    {
      image: '/img/projects/pogonauts/gallery3.jpg',
      caption: 'Pogonauts'
    }
  ],
  stats: [
    {
      value: 'Many',
      label: 'Beard and Mustache Championships',
      icon: 'bi-beard'
    },
    {
      value: '$$$',
      label: 'Raised for Movember',
      icon: 'bi-people'
    },
    {
      value: '1',
      label: 'Old RV named Consuela',
      icon: 'bi-car-front'
    }
  ],
  links: [
    {
      url: 'https://lancasteronline.com/news/hair-apparent/article_c361a232-4a77-5c8c-89b8-7bae9f283e8e.html',
      label: 'Lancaster Online Article',
      icon: 'bi-globe'
    }
  ]
};

const Pogonauts: React.FC<PogonautsProps> = ({ onClose }) => {
  return <ProjectTemplate data={pogonautsData} />;
};

export default Pogonauts; 