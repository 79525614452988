import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

export interface ProjectData {
  id: string;
  title: string;
  tagline: string;
  year: string;
  role: string;
  status: 'ongoing' | 'works' | 'past' | 'ideas';
  type: 'software' | 'adventure' | 'event' | 'community' | 'product' | 'podcast';
  thumbnailImage: string;
  overview: {
    description: string;
    motivation: string;
    solution: string;
  };
  keyFeatures?: {
    title: string;
    description: string;
    icon?: string;
  }[];
  activities?: {
    title: string;
    description: string;
    icon?: string;
  }[];
  gallery: {
    image: string;
    caption?: string;
  }[];
  stats?: {
    value: string | number;
    label: string;
    icon?: string;
  }[];
  technologies?: string[];
  links?: {
    url: string;
    label: string;
    icon: string;
  }[];
  resources?: {
    title: string;
    description: string;
    url: string;
    icon: string;
  }[];
}

interface ProjectNavItem {
  id: string;
  title: string;
  tagline: string;
  thumbnailImage: string;
  status: string;
}

interface ProjectTemplateProps {
  data: ProjectData;
  allProjects?: ProjectNavItem[];
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({ data, allProjects }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const slides = data.gallery.map(item => ({
    src: item.image,
    alt: item.caption
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="project-case-study">
      <div className={`project-navigator ${isNavExpanded ? 'expanded' : ''}`}>
        <div className="navigator-header">
          <button 
            className="toggle-nav"
            onClick={() => setIsNavExpanded(!isNavExpanded)}
          >
            <i className={`bi bi-grid${isNavExpanded ? '-fill' : ''}`}></i>
            {isNavExpanded ? 'Close' : 'Project Portfolio'}
          </button>
        </div>
        
        {isNavExpanded && (
          <div className="navigator-grid">
            {allProjects?.map(project => (
              <button 
                key={project.id} 
                onClick={() => window.location.href = `/${project.id}`}
                className={`project-card ${project.id === data.id ? 'active' : ''}`}
              >
                <div className="project-card-image">
                  <img src={project.thumbnailImage} alt={project.title} />
                </div>
                <div className="project-card-content">
                  <h3>{project.title}</h3>
                  <p>{project.tagline}</p>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Hero */}
      <header className="project-hero">
        <div className="hero-content">
          <div className="hero-grid">
            <div className="hero-thumbnail">
              <img src={data.thumbnailImage} alt={data.title} />
            </div>
            <div className="hero-text">
              <div className="status-badge">
                <span className={data.status}>
                  {data.status === 'works' ? 'In the Works' :
                   data.status === 'ongoing' ? 'Ongoing' :
                   data.status === 'past' ? 'Past Project' :
                   data.status === 'ideas' ? 'Concept' : data.status}
                </span>
                <span className="dot">•</span>
                <span>{data.year}</span>
                <span className="dot">•</span>
                <span className="type">
                  {data.type === 'software' ? 'Software' :
                   data.type === 'adventure' ? 'Adventure' :
                   data.type === 'event' ? 'Event' :
                   data.type === 'community' ? 'Community' :
                   data.type === 'product' ? 'Product' : data.type}
                </span>
                <span className="dot">•</span>
                <span className="role">{data.role}</span>
              </div>
              <h1>{data.title}</h1>
              <p className="tagline">{data.tagline}</p>
            </div>
          </div>
        </div>
      </header>

      {/* Overview */}
      <section className="project-overview">
        <div className="container">
          <div className="overview-content">
            <div className="overview-text">
              <p>{data.overview.description}</p>
              {(data.links?.[0] || data.resources?.find(r => r.title === 'Website' || r.title.includes('Registration'))) && (
                <a 
                  href={data.resources?.find(r => r.title === 'Website' || r.title.includes('Registration'))?.url || data.links?.[0].url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="cta-link"
                >
                  <span>Visit Website</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Motivation & Solution */}
      <section className="motivtion-solution">
        <div className="container">
          <div className="cs-grid">
            <div className="cs-item">
              <span className="cs-label">The Motivation</span>
              <p>{data.overview.motivation}</p>
            </div>
            <div className="cs-item">
              <span className="cs-label">The Solution</span>
              <p>{data.overview.solution}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Stats */}
      {data.stats && (
        <section className="key-stats">
          <div className="container">
            <div className="stats-grid">
              {data.stats.map((stat, index) => (
                <div key={index} className="stat-item">
                  <div className="stat-value">{stat.value}</div>
                  <div className="stat-label">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {/* Features */}
      {(data.keyFeatures || data.activities) && (
        <section className="key-features">
          <div className="container">
            <h2>
              {data.type === 'software' ? 'Key Features' :
               data.type === 'adventure' ? 'Activities' :
               data.type === 'event' ? 'Event Details' :
               data.type === 'community' ? 'Programs' :
               'Highlights'}
            </h2>
            <div className="features-grid">
              {(data.keyFeatures || data.activities)?.map((item, index) => (
                <div key={index} className="feature-card">
                  <div className="feature-card-header">
                    {item.icon && <i className={`bi ${item.icon}`}></i>}
                    <h3>{item.title}</h3>
                  </div>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {/* Resources */}
      {data.resources && (
        <section className="project-resources">
          <div className="container">
            <h2>Resources</h2>
            <div className="resources-grid">
              {data.resources.map((resource, index) => (
                <a 
                  key={index}
                  href={resource.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="resource-card"
                >
                  <div className="resource-icon">
                    <i className={`bi ${resource.icon}`}></i>
                  </div>
                  <div className="resource-content">
                    <h3>{resource.title}</h3>
                    <p>{resource.description}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </section>
      )}

      {/* Gallery */}
      <section className="project-gallery">
        <div className="container">
          <h2>Gallery</h2>
          <div className="gallery-grid">
            {data.gallery?.map((item, index) => (
              <div 
                key={index} 
                className="gallery-item"
                onClick={() => {
                  setLightboxIndex(index);
                  setLightboxOpen(true);
                }}
              >
                <img 
                  src={item.image} 
                  alt={item.caption || `Gallery ${index + 1}`}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        index={lightboxIndex}
        slides={slides}
      />
    </div>
  );
};

export default ProjectTemplate; 