import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface AHAHProps {
  onClose: () => void;
}

export const ahahData: ProjectData = {
  id: 'ahah',
  title: 'A Horse A Half (AHAH)',
  tagline: 'Unique outdoor adventures for the adventurous',
  year: '2020',
  role: 'Co-Adventurer',
  status: 'ideas',
  type: 'adventure',
  thumbnailImage: '/img/projects/ahah/thumbnail.png',
  overview: {
    description: "A Horse A Half (AHAH) is a concept for an adventure group that organizes unusual and unique outdoor adventures.",
    motivation: "To explore and adventure in unusual, unique, and memorable ways - while not taking ourselves too seriously.",
    solution: "Combine forces with some equally ambitious and adventurous, albeit a bit wild, friends to create unique adventures."
  },
  activities: [
    {
      title: 'BV3',
      description: 'Summit a 14er, bike from trailhead to river, and float on packrafts and SUPs - all in one day',
      icon: 'bi-mountain'
    },
    {
      title: 'Cinco de Hiko',
      description: '5KM hike and BBQ celebration every Cinco de Mayo',
      icon: 'bi-flag'
    },
    {
      title: 'FIBArk Hooligan Race',
      description: 'Annual participation in the iconic whitewater festival event',
      icon: 'bi-water'
    }
  ],
  gallery: [
    {
      image: '/img/projects/ahah/gallery1.jpg',
      caption: 'BV3 Summit View'
    },
    {
      image: '/img/projects/ahah/gallery2.jpg',
      caption: 'Cinco de Hiko Trail'
    },
    {
      image: '/img/projects/ahah/gallery3.jpg',
      caption: 'FIBArk Hooligan Race'
    },
    {
      image: '/img/projects/ahah/gallery4.jpg',
      caption: 'Adventure Planning'
    }
  ],
  stats: [
    {
      value: '∞',
      label: 'Crazy Ideas',
      icon: 'bi-lightbulb'
    },
    {
      value: 4,
      label: 'BV3s',
      icon: 'bi-trophy'
    },
    {
      value: 2,
      label: 'Hooligan Races',
      icon: 'bi-water'
    },
    {
      value: 'Lots More!',
      label: 'In the Works',
      icon: 'bi-arrow-right'
    }
  ],
  technologies: [],
  links: []
};

const AHAH: React.FC<AHAHProps> = ({ onClose }) => {
  return <ProjectTemplate data={ahahData} />;
};

export default AHAH; 