import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { images } from '../assets';

interface TooltipProps {
  text: string;
  content: string;
  projectId?: string;
  thumbnail?: string;
  websiteUrl?: {
    url: string;
    name: string;
  };
}

const About: React.FC = () => {
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleProjectClick = (projectId: string) => {
    window.location.href = `/${projectId}`;
  };

  const Tooltip = ({ text, content, projectId, thumbnail, websiteUrl }: TooltipProps) => (
    <Tippy 
      content={
        <div className="tooltip-content">
          {thumbnail && (
            <div 
              className="tooltip-thumbnail-container"
              onClick={() => setExpandedImage(thumbnail)}
            >
              <img 
                src={thumbnail} 
                alt={content} 
                className="tooltip-thumbnail"
              />
              <div className="thumbnail-overlay">
                <i className="bi bi-zoom-in"></i>
              </div>
            </div>
          )}
          <div className="tooltip-description">{content}</div>
          {(websiteUrl || projectId) && (
            <div className="tooltip-actions">
              {websiteUrl && (
                <a 
                  href={websiteUrl.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="website-link"
                >
                  {websiteUrl.name} <i className="bi bi-link-45deg"></i>
                </a>
              )}
              {projectId && (
                <button 
                  className="view-project-link"
                  onClick={() => handleProjectClick(projectId)}
                >
                  View Project Details <i className="bi bi-box-arrow-up-right"></i>
                </button>
              )}
            </div>
          )}
        </div>
      }
      placement="right"
      interactive={true}
      appendTo="parent"
      popperOptions={{
        strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
              padding: 12
            }
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom', 'top', 'left'],
            }
          }
        ]
      }}
    >
      <span className="reference-term">{text}</span>
    </Tippy>
  );

  return (
    <div className="about-page">
      <div className="container">
        <h2>A little about me</h2>
        <div className="content">
          <div className="image-quote-container">
            <div className="profile-image">
              <img src={images.profileImage} alt="" />
            </div>
            <blockquote className="blockquote">
              <p>"We are more fulfilled when we are involved with something bigger than ourselves"</p>
              <footer className="blockquote-footer">
                - Astronaut John Glenn
              </footer>
            </blockquote>
          </div>
          <p>
            While I've been in engineering and project roles most of my career, 
            I'm a creative and creator at heart. I enjoy organizing unique and 
            (hopefully) memorable experiences that bring people together. This includes organizing an   <Tooltip text="annual marathon" 
            content="Sloan's Lake Search Party" 
            projectId="slsp" 
            thumbnail="/img/projects/slsp/thumbnail.png" 
            /> around Denver's largest lake in search of the mythical creature called  <Tooltip text="Goosifer" 
            content="aka Sloan's Lake Monster & Colfax Creature" 
            websiteUrl={{ url: "https://goosifer.net", name: "Learn more at Goosifer.net" }} 
            /> to hosting a   <Tooltip text="5km hike" 
            content="Cinco de Hiko" 
            websiteUrl={{ url: "https://gatherly.org/event/cinco-de-hiko-24", name: "2024 Event Page" }}
            /> and cookout overlooking the front range.
          </p>
          <p>
            I've also founded and hosted a nerdy and sometimes salacious  <Tooltip 
              text="speaking series" 
              content="Nerd Nite Orlando" 
              projectId="nno" 
              thumbnail="/img/projects/nno/thumbnail.png"
              websiteUrl={{
                url: "https://orlando.nerdnite.com",
                name: "Nerd Nite Orlando"
              }}
            /> held monthly at a bar, an all-day <Tooltip 
              text="Easter beer hunt" 
              content="Beaster"
              thumbnail="/img/projects/beaster/thumbnail.png" 
            /> and rare meat cookout, and built <Tooltip text="anything that floats" content="Hooligan race 2024" thumbnail="/img/projects/ahah/gallery5.jpg" projectId="ahah" /> "boats" to compete in the oldest running whitewater festival, FIBArk. I've even somehow convinced a couple of my closest friends to hike above 14,000 feet at sunrise before biking and rafting all in <Tooltip text="one day" content="Buena Vista 3 (BV3) - Mt. Antero 2024" thumbnail="/img/projects/ahah/gallery1.jpg" projectId="ahah" />.
          </p>
          <p>
            In my free time, you'll find me floating down the river, walking up mountains, biking around town, hanging with my ol' pup Banksy, and camping while also planning the next great adventure with my wife  (so far we've visited nearly 50 countries together). I guess I'm also sort of a Type II fun kind of person. I've completed marathons and <Tooltip text="ultramarathons" content="Biffledinked" />, including a 24-hour, 50-mile <Tooltip text="loop-based race" content="Joe Relaxo" />, a couple of <Tooltip text="Ironman triathlons" content="IMFL, IMCHOO" />, and numerous half Ironmans. I've also biked up <Tooltip text="Mt. Blue Sky" content="Bob Cook Memorial Hill Climb" />, ridden from <Tooltip text="Denver to Vail" content="Triple Bypass" />, and covered 150 miles of multi-use trails around town in a single day all on my trusty, albeit a bit heavy, gravel bike.
          </p>
          <p>
            Throughout my career, I've been fortunate to work on awe-inspiring, impactful, and large-scale efforts at <Tooltip text="NASA" content="Managed launch control application development" thumbnail="/img/nasa.jpg" websiteUrl={{
                url: "https://blogs.nasa.gov/groundsystems/2017/04/05/new-ground-launch-sequencer-software-demonstrated-in-launch-control-center/",
                name: "Launch Countdown Software News"
              }} />, the <Tooltip text="U.S. Senate" content="Served as Space & Science Subcommittee professional staff to Sen. Bill Nelson" thumbnail="/img/senate.jpg"  />, and <Tooltip text="Amazon" content="Managed massive scale data analytics programs" thumbnail="/img/amazon.jpg"/>, but I've always been an entrepreneur at heart. I co-founded a small <Tooltip text="startup" content="eJourney 360 - one of the very first 360 virtual tour companies back in early 2000s" thumbnail="/img/ejourney.jpg" /> while in college and am always working on <a href="/projects" onClick={(e) => { e.preventDefault(); window.location.href = '/projects'; }}>passion projects</a>. My goal has been to make sense out of chaos and cut through all the noise so that I can help enable audacious missions and realize bold visions. I'd like to think that it was my curiosity and creativity that led me to some of the toughest and most rewarding careers out there!
          </p>
          <p>
My hope is to have a net positive impact on this world and hopefully have some fun along the way. Stay tuned to see what I'm up to next!          </p>

        </div>
      </div>

      {/* Image Modal */}
      {expandedImage && (
        <div 
          className="image-modal-overlay"
          onClick={() => setExpandedImage(null)}
        >
          <div className="image-modal">
            <img src={expandedImage} alt="Expanded view" />
            <button 
              className="close-modal"
              onClick={() => setExpandedImage(null)}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default About; 