import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface TrailfriendProps {
  onClose: () => void;
}

export const trailfriendData: ProjectData = {
  id: 'trailfriend',
  title: 'Trailfriend',
  tagline: 'Connect with fellow hikers, discover new trails, and plan your next journey together.',
  year: '2024',
  role: 'Adventurer',
  status: 'ideas',
  type: 'software',
  thumbnailImage: '/img/projects/trailfriend/thumbnail.png',
  overview: {
    description: "Trailfriend is a platform that connects hikers with fellow adventurers, allowing them to discover new trails, plan their next journey together, and share their experiences.",
    motivation: "After way too many solo hiking trips in 2024, I started thinking I needed to find some more hiking friends to tackle some of the crazier hikes in Colorado.",
    solution: "A planning and social platform that includes a list of popular trails (Coloradoo 14ers and Centennials to start), a visual calendar to pick trip dates, and a built-in messaging system to coordinate with fellow hikers."
  },
  keyFeatures: [
    {
      title: 'Trip Planning',
      description: 'Plan your next journey together with a visual calendar.',
      icon: 'bi-calendar-check'
    },
    {
      title: 'Messaging',
      description: 'Chat with fellow hikers to coordinate your next adventure.',
      icon: 'bi-chat-dots'
    },
    {
      title: 'Bucketlists',
      description: 'Create and share your bucketlist with fellow hikers.',
      icon: 'bi-list-check'
    }
  ],
  gallery: [
    {
      image: '/img/projects/trailfriend/gallery1.jpg',
      caption: 'Trailfriend'
    },
    {
      image: '/img/projects/trailfriend/gallery2.jpg',
      caption: 'Trailfriend'
    },
    {
      image: '/img/projects/trailfriend/gallery3.jpg',
      caption: 'Trailfriend'
    },
    {
      image: '/img/projects/trailfriend/gallery4.jpg',
      caption: 'Trailfriend'
    }
  ],
  stats: [
  ],
  technologies: [
  ],
  links: [
    {
      url: 'https://trailfriend.com',
      label: 'Visit Website',
      icon: 'bi-globe'
    }
  ]
};

const Trailfriend: React.FC<TrailfriendProps> = ({ onClose }) => {
  return <ProjectTemplate data={trailfriendData} />;
};

export default Trailfriend; 