import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface BandBaconProps {
  onClose: () => void;
}

// Project Data
export const bandBaconData: ProjectData = {
  id: 'bandbacon',
  title: 'Band Bacon',
  tagline: 'Explore the interconnections between musicians and bands',
  year: '2024',
  role: 'Musical Explorer',
  status: 'ideas',
  type: 'software',
  thumbnailImage: '/img/projects/bandbacon/thumbnail.png',
  overview: {
    description: "BandBacon is a platform that explores and visualizes the interconnections between musicians and bands, similar to the concept of 'six degrees of separation.' It maps out how artists are connected through their collaborations, band memberships, and musical influences, creating a fascinating web of musical relationships.",
    motivation: "In a world where music discovery is increasingly controlled by opaque algorithms, I wanted to create a more transparent and intentional way to find new music. By visualizing the relationships between artists - their side projects, collaborations, and musical journeys - users can naturally explore and discover music through explicit connections rather than black-box recommendations.",
    solution: "An interactive visualization platform that lets you browse through musical relationships, revealing surprising connections between bands you love and helping you find new artists through a clear path of musical connections."
  },
  keyFeatures: [
    {
      title: 'Interactive Visualization',
      description: 'Explore the complex web of musical connections through an intuitive, interactive interface.',
      icon: 'bi-diagram-3'
    },
    {
      title: 'Artist Connections',
      description: 'Discover how musicians are connected through collaborations, band memberships, and past projects.',
      icon: 'bi-people'
    },
    {
      title: 'Music Discovery',
      description: 'Listen to music from artists you might not have heard of before.',
      icon: 'bi-music-note'
    }
  ],
  gallery: [
    {
      image: '/img/projects/bandbacon/gallery1.jpg',
      caption: 'Network Visualization Interface'
    },
    {
      image: '/img/projects/bandbacon/gallery2.jpg',
      caption: 'Artist Connection Details'
    },
    {
      image: '/img/projects/bandbacon/gallery3.jpg',
      caption: 'Timeline View'
    },
    {
      image: '/img/projects/bandbacon/gallery4.jpg',
      caption: 'Discovery Dashboard'
    }
  ],
  stats: [
    {
      value: '1M+',
      label: 'Artist Connections',
      icon: 'bi-link'
    },
    {
      value: '100K+',
      label: 'Artists',
      icon: 'bi-person'
    },
    {
      value: '50K+',
      label: 'Bands',
      icon: 'bi-music-note'
    },
    {
      value: '∞',
      label: 'Discoveries',
      icon: 'bi-infinity'
    }
  ],
  technologies: [
    'React',
    'TypeScript',
    'D3.js',
    'Node.js',
    'Neo4j',
    'GraphQL'
  ],
  links: [
    {
      url: 'https://bandbacon.app',
      label: 'Visit Website',
      icon: 'bi-globe'
    },
    {
      url: 'https://github.com/yourusername/bandbacon',
      label: 'View Source',
      icon: 'bi-github'
    }
  ]
};

// Component
const BandBacon: React.FC<BandBaconProps> = ({ onClose }) => {
  return <ProjectTemplate data={bandBaconData} />;
};

export default BandBacon; 