import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface SeededProps {
  onClose: () => void;
}

export const seededData: ProjectData = {
  id: 'seeded',
  title: 'Seeded',
  tagline: 'Stories of Innovation',
  year: '2022',
  role: 'Co-Founder',
  status: 'past',
  type: 'podcast',
  thumbnailImage: '/img/projects/seeded/thumbnail.png',
  overview: {
    description: "A podcast about the stories of innovation and the lessons of what it truly takes to grow something new.",
    motivation: "We wanted to create a podcast about innovation that was told through the stories of the people who made it happen, and showcase how collaborative and creative, yet repeatable and learnable the process of innovation can be.",
    solution: "The solution was intended to be a monthly podcast that would feature a story or two about innovation along with lessons that could let people 'dig deeper' into learning more. We wrote and recorded a few episodes, but never got around to publishing them."
  },
  activities: [
    {
      title: 'Podcast',
      description: 'A podcast about the stories of innovation and the lessons of what it truly takes to grow something new.',
      icon: 'bi-mic'
    }
  ],
  gallery: [
    {
      image: '/img/projects/seeded/gallery1.jpg',
      caption: 'Seeded'
    }
  ],
  stats: [
    {
      value: '2',
      label: 'Enthusiastic co-hosts',
      icon: 'bi-people'
    },
    {
      value: '100s',
      label: 'Hours researching and writing',
      icon: 'bi-hourglass'
    },
    {
      value: '~5',
      label: 'Episodes in varying stages of completion',
      icon: 'bi-mic'
    }
  ],
  links: [
    {
      url: 'https://seededpodcast.com/',
      label: 'Seeded Podcast',
      icon: 'bi-globe'
    },
  ]
};

const Seeded: React.FC<SeededProps> = ({ onClose }) => {
  return <ProjectTemplate data={seededData} />;
};

export default Seeded; 