import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface MuzedProps {
  onClose: () => void;
}

export const muzedData: ProjectData = {
  id: 'muzed',
  title: 'Muzed',
  tagline: 'Bringing art to life through storytelling',
  year: '2024',
  role: 'Art Explorer',
  status: 'ideas',
  type: 'software',
  thumbnailImage: '/img/projects/muzed/thumbnail.png',
  overview: {
    description: "Muzed brings art to life through storytelling. Using AI vision technology, the app instantly recognizes artwork around you - from paintings and sculptures to murals and installations. Once identified, Muzed delivers immersive audio narratives and rich multimedia stories that reveal the fascinating histories, creative processes, and cultural significance behind each piece.",
    motivation: "Art has stories that deserve to be told and heard. Every piece has a unique history, context, and meaning that often goes undiscovered. Muzed aims to bridge this gap by creating an accessible platform where artists, curators, and art enthusiasts can share these stories.",
    solution: "A mobile platform that combines AI art recognition with community-driven audio storytelling, making art more engaging and accessible for everyone - especially those who may not typically enjoy the traditional museum experience."
  },
  keyFeatures: [
    {
      title: 'AI Art Recognition',
      description: 'Instantly identify art using advanced computer vision technology.',
      icon: 'bi-camera'
    },
    {
      title: 'Audio Tidbits',
      description: 'Listen to immersive and engaging 30 sec clips about each piece of art, shared by the community.',
      icon: 'bi-mic'
    },
    {
      title: 'Community Contributions',
      description: 'Share your own perspectives and stories about the art you love.',
      icon: 'bi-people'
    }
  ],
  gallery: [
    {
      image: '/img/projects/muzed/gallery1.jpg',
      caption: 'Art Recognition Interface'
    },
    {
      image: '/img/projects/muzed/gallery2.jpg',
      caption: 'Audio Story Player'
    }
  ],
  technologies: [
    'React Native',
    'Node.js',
    'TensorFlow',
    'OpenAI',
    'AWS'
  ],
  links: [
    {
      url: 'https://muzed.app',
      label: 'Coming Soon',
      icon: 'bi-globe'
    }
  ]
};

const Muzed: React.FC<MuzedProps> = ({ onClose }) => {
  return <ProjectTemplate data={muzedData} />;
};

export default Muzed; 