import React, { useState } from 'react';

interface AdventureStep {
  text: string;
  choices: {
    text: string;
    nextStep: string;
    icon?: string;
  }[];
}

const adventureSteps: Record<string, AdventureStep> = {
  start: {
    text: "Looks like you want to connect. How would you like to proceed?",
    choices: [
      { 
        text: "Let's go on an adventure", 
        nextStep: "adventure",
        icon: "bi-globe" 
      },
      { 
        text: "Let's collaborate on a project", 
        nextStep: "project",
        icon: "bi-lightbulb" 
      },
      { 
        text: "Let's get down to business", 
        nextStep: "professional",
        icon: "bi-briefcase"
      }
    ]
  },
  professional: {
    text: "Got it. Let's make this official.",
    choices: [
      {
        text: "Connect on LinkedIn",
        nextStep: "linkedin",
        icon: "bi-linkedin"
      },
      {
        text: "Schedule a Coffee Chat",
        nextStep: "coffee",
        icon: "bi-cup-hot"
      }
    ]
  },
  adventure: {
    text: "Always up for an adventure! What kind are you thinking?",
    choices: [
      {
        text: "Hiking, biking, and rafting oh my!",
        nextStep: "adventure_planning",
        icon: "bi-compass-fill"
      },
      {
        text: "Run a marathon",
        nextStep: "running",
        icon: "bi-person-walking"
      },
      {
        text: "Create a new adventure!",
        nextStep: "adventure_planning",
        icon: "bi-stars"
      }
    ]
  },
  project: {
    text: "Exciting! What kind of project did you have in mind?",
    choices: [
      {
        text: "Something techy",
        nextStep: "project_planning",
        icon: "bi-code-slash"
      },
      {
        text: "Something fun",
        nextStep: "project_planning",
        icon: "bi-calendar-event"
      }
    ]
  }
};

// Add social/contact endpoints
const contactEndpoints: Record<string, { title: string, action: () => void }> = {
  linkedin: {
    title: "Great choice! Let's connect on LinkedIn",
    action: () => window.open('https://www.linkedin.com/in/joshmanning/', '_blank')
  },
  coffee: {
    title: "Coffee is always a good idea!",
    action: () => window.open('', '_blank')
  },
  adventure_planning: {
    title: "Let's go on an adventure!",
    action: () => window.open('https://calendly.com/joshjmanning/connect', '_blank')
  },
  project_planning: {
    title: "Let's collaborate on a project!",
    action: () => window.open('https://calendly.com/joshjmanning/connect', '_blank')
  },
  running: {
    title: "Let's go on a run",
    action: () => window.open('https://calendly.com/joshjmanning/connect', '_blank')
  }
  // Add other endpoints...
};

interface ContactAdventureProps {
  onClose: () => void;
}

const ContactAdventure: React.FC<ContactAdventureProps> = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState('start');
  const [history, setHistory] = useState<string[]>(['start']);

  const handleChoice = (nextStep: string) => {
    if (contactEndpoints[nextStep]) {
      contactEndpoints[nextStep].action();
      onClose();
    } else {
      setCurrentStep(nextStep);
      setHistory([...history, nextStep]);
    }
  };

  const goBack = () => {
    if (history.length > 1) {
      const newHistory = history.slice(0, -1);
      setHistory(newHistory);
      setCurrentStep(newHistory[newHistory.length - 1]);
    }
  };

  const step = adventureSteps[currentStep];

  return (
    <div className="contact-adventure-modal">
      <div className="adventure-content">
        <div className="adventure-header">
          <button 
            className="back-btn" 
            onClick={goBack}
            disabled={history.length <= 1}
          >
            <i className="bi bi-arrow-left"></i>
          </button>
          <button className="close-btn" onClick={onClose}>
            <i className="bi bi-x"></i>
          </button>
        </div>
        
        <div className="adventure-step">
          <p className="step-text">{step.text}</p>
          <div className="choice-grid">
            {step.choices.map((choice, index) => (
              <button
                key={index}
                className="choice-btn"
                onClick={() => handleChoice(choice.nextStep)}
              >
                {choice.icon && <i className={`bi ${choice.icon}`}></i>}
                {choice.text}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAdventure; 