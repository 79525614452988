import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SlidingPanel from './components/SlidingPanel';
import Hero from './components/Hero';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import ProjectPage from './components/ProjectPage';
import './styles/main.css';
import './styles/project-template.css';
import './styles/sliding-panel.css';
import './styles/app.css';
import './styles/portfolio.css';

const App: React.FC = () => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);

  const togglePanel = () => {
    setIsPanelExpanded(!isPanelExpanded);
  };

  return (
    <div className="App">
      <SlidingPanel 
        isExpanded={isPanelExpanded}
        onToggle={togglePanel}
      />
      <main className={`main-content ${isPanelExpanded ? 'panel-expanded' : ''}`}>
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={<Hero />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Portfolio />} />
          <Route path="/:projectId" element={<ProjectPage />} />
        </Routes>
        <Footer />
      </main>
    </div>
  );
};

export default App;