import React from 'react';
import ProjectTemplate from './ProjectTemplate';
import { ProjectData } from './ProjectTemplate';

interface TGBProps {
  onClose: () => void;
}

export const tgbData: ProjectData = {
  id: 'tgb',
  title: 'TriGoodBeer Triathlon Team',
  tagline: 'A triathlon team that trains and parties hard!',
  year: '2010',
  role: 'Triathlete',
  status: 'past',
  type: 'community',
  thumbnailImage: '/img/projects/tgb/thumbnail.png',
  overview: {
    description: "A triathlon team that doesn't take itself too seriously. We trained hard but we also had a lot of fun. Many of us started out doing shorter, sprint triathlons and ended up doing several Ironman triathlons together.",
    motivation: "Traithlon is a very competitive and often solo sport. We wanted to create a team that not only trained together, but could have fun. Plus, on long training days you want to have a good group around because you're spending A LOT of time together.",
    solution: "A team of fun-loving group of triathletes that wore a ledershosen kit and cheered not only each other on the course but would stay until the very last person finished!"
  },
  activities: [
    {
      title: 'Triathlons',
      description: 'Competing in sprint, olympic, and Ironman triathlons including having membes compete at the Ironman World Championships in Kona, Hawaii.',
      icon: 'bi-trophy'
    },
    {
      title: 'Ledershosen',
      description: 'You can\'t take yourself too seriously when you\'re wearing ledershosen!',
      icon: 'bi-star'
    },
    {
      title: 'Cheering!',
      description: 'Cheering not only each other on the course but every single person that finished!',
      icon: 'bi-person-arms-up'
    }
  ],
  gallery: [
    {
      image: '/img/projects/tgb/gallery1.jpg',
      caption: 'TGB Event'
    },
    {
      image: '/img/projects/tgb/gallery2.jpg',
      caption: 'TGB Event'
    },
    {
      image: '/img/projects/tgb/gallery3.jpg',
      caption: 'TGB Event'
    }
  ],
  stats: [
    {
      value: '20+',
      label: 'Fun-loving Triathletes',
      icon: 'bi-person'
    },
    {
      value: '1',
      label: 'Awesome Ledershosen Kit',
      icon: 'bi-shirt'
    },
    {
      value: 'Many',
      label: 'Triathlons Completed',
      icon: 'bi-running'
    }
  ],
  links: [
    {
      url: 'http://trigoodbeer.com/',
      label: 'TGB Website',
      icon: 'bi-globe'
    }
  ]
};

const TGB: React.FC<TGBProps> = ({ onClose }) => {
  return <ProjectTemplate data={tgbData} />;
};

export default TGB; 